<template>
    <div class="comp-Basic">
        <el-card>
            <div slot="header">车辆基本信息</div>

            <el-form :model="propDataForm">
                <el-form-item label="所属部门">
                    <el-select v-model="propDataForm.did" clearable filterable>
                        <el-option v-for="item in disOpt" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="所属班组">
                    <el-select v-model="propDataForm.tid" clearable filterable>
                        <el-option v-for="item in disOpt" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="所属员工">
                    <el-select v-model="propDataForm.tid" clearable filterable>
                        <el-option v-for="item in disOpt" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="车牌号">
                    <el-input v-model="propDataForm.number"></el-input>
                </el-form-item>

                <el-form-item label="车辆识别号">
                    <el-input v-model="propDataForm.clsbh"></el-input>
                </el-form-item>

                <el-form-item label="发动机号">
                    <el-input v-model="propDataForm.fdjh"></el-input>
                </el-form-item>

                <el-form-item label="购置时间">
                    <el-date-picker v-model="propDataForm.gzsj" type="datetime" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择购置时间"></el-date-picker>
                </el-form-item>

                <el-form-item label="退出日期">
                    <el-date-picker v-model="propDataForm.tcrq" type="datetime" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd HH:mm:ss" placeholder="请选择购置时间"></el-date-picker>
                </el-form-item>

                <el-form-item label="生产厂家">
                    <el-input v-model="propDataForm.sccj"></el-input>
                </el-form-item>

                <el-form-item label="型号">
                    <el-input v-model="propDataForm.xh"></el-input>
                </el-form-item>

                <el-form-item label="动力类型">
                    <el-input v-model="propDataForm.dllx"></el-input>
                </el-form-item>

                <el-form-item label="等级">
                    <el-input v-model="propDataForm.dj"></el-input>
                </el-form-item>

                <el-form-item label="外型尺寸">
                    <el-input v-model="propDataForm.wxcc"></el-input>
                </el-form-item>

                <el-form-item label="座位数">
                    <el-input v-model="propDataForm.zws"></el-input>
                </el-form-item>

                <el-form-item label="使用资质">
                    <el-input v-model="propDataForm.syzz"></el-input>
                </el-form-item>

                <el-form-item label="排放标准">
                    <el-input v-model="propDataForm.pwbz"></el-input>
                </el-form-item>

                <el-form-item label="运行路线">
                    <el-input v-model="propDataForm.yxlx"></el-input>
                </el-form-item>

                <el-card>
                    <div slot="header">上传照片</div>

                    <el-form-item label="道路运输证照片">
                        <el-upload :action="$http.common.upload" list-type="picture-card"
                            :on-success="dlyszPicUpSuccess" :headers="{ token: $store.state.token }"
                            :on-preview="clickDlyszPic" :on-remove="removeDlyszPic" :file-list="dlyszPic_arr"
                            :limit="1">
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>

                    </el-form-item>

                    <el-form-item label="行驶证照片">
                        <el-upload :action="$http.common.upload" list-type="picture-card" :on-success="xszPicUpSuccess"
                            :headers="{ token: $store.state.token }" :on-preview="clickXszPic" :on-remove="removeXszPic"
                            :file-list="xszPic_arr" :limit="1">
                            <i class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>

                    <el-dialog :visible.sync="dialogVisible" append-to-body>
                        <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                </el-card>

            </el-form>

            <el-button @click="clickMe">点我</el-button>
        </el-card>
    </div>
</template>

<script>
export default {
    props: ['propDataForm'],
    data() {
        return {
            disOpt: [
                { id: 1, name: '部门1' },
                { id: 2, name: '部门2' }
            ],
            dlyszPic_arr: [],  // 道路运输证照片集
            xszPic_arr: [],  // 行驶证照片集

            dialogVisible: false,  // 显示上传文件的弹窗(显示状态)
            dialogImageUrl: '',  // 显示弹窗的图片url
        }
    },
    created() {
        if(this.propDataForm) {
            if(this.propDataForm.dlyszPic) {
                this.dlyszPic_arr = [{url: this.$http.common.pic + this.propDataForm.dlyszPic}]
            }
            
            if(this.propDataForm.xszPic) {
                this.xszPic_arr = [{url: this.$http.common.pic + this.propDataForm.xszPic}]
            }
        }
    },
    methods: {
        clickMe() {
            console.log(this.propDataForm)
        },
        // 文件上传成功(道路运输证照片)
        dlyszPicUpSuccess(file) {
            console.log(file)
            if (file.code === 0) {
                this.dialogImageUrl = this.$http.common.pic + file.data
                this.propDataForm.dlyszPic = file.data
            }
        },
        // 点击上传成功的文件(道路运输证照片)
        clickDlyszPic() {
            if (this.dialogImageUrl) {
                this.dialogVisible = true;
            }
        },
        // 删除上传文件(道路运输证照片)
        removeDlyszPic() {
            this.propDataForm.dlyszPic = ''
        },

        // 文件上传成功(行驶证照片)
        xszPicUpSuccess(file) {
            if (file.code === 0) {
                this.dialogImageUrl = this.$http.common.pic + file.data
                this.propDataForm.xszPic = file.data
            }
        },
        // 点击上传成功的文件(行驶证照片)
        clickXszPic() {
            if (this.dialogImageUrl) {
                this.dialogVisible = true;
            }
        },
        // 删除上传文件(行驶证照片)
        removeXszPic() {
            this.propDataForm.xszPic = ''
        }
    }
}
</script>